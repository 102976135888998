.FAQ .content {
  margin-top: 70px;
  margin-bottom: 70px;
  overflow: visible;
}

.FAQ .content-border:hover {
  border: 1px dashed #323232;
}

.FAQ .cms-edit-btn {
  position: absolute;
  right: 20vw;
}

.FAQ .flush {
  justify-content: center;
  align-items: center;
}

.FAQ .accordionitem {
  margin-top: 7px;
}

.FAQ .accordionheader {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */
  border-bottom: 2px solid #7d6746;
  color: #323232;
}

.FAQ .accordionheader p {
  margin: 0;
}

.FAQ .accordionbody {
  margin-top: 30px;
  padding-bottom: 60px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 17px;
  line-height: 19px;
  align-items: center;
  border-bottom: 2px solid #7d6746;
  color: #7d6746;
}

.FAQ .accordionbody p {
  margin: 0;
}

.FAQ .accordion-button:not(.collapsed) {
  color: #ffffff;
  background-color: #b1a390;
}

.FAQ .accordion-button:focus,
.accordion-button:active {
  outline: none !important;
  box-shadow: none;
}

.FAQ .accordion-button.collapsed::after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%237D6746'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.FAQ .accordion-button:after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.FAQ .FAQbackground {
  background-size: cover;
  background-position: center;
  height: 18.4375vw;
  background-image: url("../img/png/FAQ-background.png");
}

@media (max-width: 1313px) {
  .FAQ .FAQbackground {
    height: 242px;
  }
}

@media (max-width: 576px) {
  .FAQ .FAQbackground {
    height: 42.0289855vw;
    background-image: url("../img/png/FAQ-smallbackground.png");
  }
}
