.Uploadphoto .bg{
    display: flex; 
    justify-content: center; 
    align-items: center;
}

.Uploadphoto .uploadtext{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;

    color: #7D6746;
}

.Uploadphoto .uploadarea{
    width: 937px;
    height: 340px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
    border: 3px dashed #7D6746;
    box-sizing: border-box;
    border-radius: 15px;
}

.Uploadphoto .children{
    
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.Uploadphoto .tickbox{

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;  
    color: #7D6746;
}

.Uploadphoto .uploadtext{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: #7D6746;
}

.Uploadphoto .uploadbtn{
    width: 100%;
    text-align: center;
}

.Uploadphoto .Filename{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;


    color: #464646;
}

.Uploadphoto .JPGPNGIcon{
    width: 30px;
    height: 30px;
}

.Uploadphoto .DeleteIcon{
    width: 14px;
    height: 14px;
    cursor: pointer;
}


.Uploadphoto .progresstext{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;


    color: #676767;
}

