.joindc-container {
  height: 100%;
}

.joindc-font-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  color: #7d6746;
}

.div-requirement-container {
  height: 390px;
  margin-bottom: 30px;
}

.div-center {
  display: flex;
  margin: auto;
  width: 32%;
  /* border: 0px solid #ffffff; */
  padding: 14px;
  text-align: center;
}

.div-step-center {
  display: flex;
  margin: auto;
  width: 45%;
  /* border: 0px solid #ffffff; */
  padding: 14px;
  text-align: center;
}

.div-font-center {
  padding-left: 20px;
}

.div-require {
  display: flex;
}

.div-font {
  padding-top: 10px;
  padding-left: 15px;
}

.div-become-mem {
  background: #f2efec;
  width: 50%;
}

.div-holding {
  background: #e5e0da;
  width: 50%;
}

.div-step1 {
  width: 34%;
  text-align: center;
  background: #ffffff;
}

.div-step2 {
  width: 34%;
  text-align: center;
  background: #f2efec;
}

.div-step3 {
  width: 34%;
  text-align: center;
  background: #e5e0da;
}

.div-font {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
}

.div-font .eng-title {
  font-size: 12px;
  line-height: 15px;
  color: #868686;
  padding-top: 30px;
  padding-left: 63px;
}

.div-font .chi-title {
  font-size: 30px;
  line-height: 36px;
  color: #7d6746;
  padding-left: 63px;
}

.div-svg {
  text-align: center;
  padding-top: 34px;
  padding-bottom: 41px;
}
