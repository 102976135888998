.div-modal-input {
  text-align: center;
}

.input-box-white {
  width: 36px;
  height: 36px;
  margin-left: 3.5px;
  margin-right: 3.5px;
  text-align: center;
}

.input-box-dis {
  width: 36px;
  height: 36px;
  margin-left: 3.5px;
  margin-right: 3.5px;
  text-align: center;
}

.div-input-name-mo {
  margin-top: 30px;
  text-align: center;
  display: flex;
  margin-left: 45px;
  width: 80%;
}

.div-input-eng {
  flex-grow: 0.95;
}

.input-eng-border {
  border-top: 1px solid #9e9e9e;
  border-right: 1px solid #9e9e9e;
  border-left: 1px solid #9e9e9e;
  height: 5px;
}

.div-input-int-mo {
  flex-grow: 4;
}

.div-int-font {
  margin-left: 25px;
}

.input-int-border {
  border-top: 1px solid #9e9e9e;
  border-right: 1px solid #9e9e9e;
  border-left: 1px solid #9e9e9e;
  height: 5px;
  width: 88%;
  margin-left: 30px;
}

.div-input-brackets-mo {
  flex-grow: 0.1;
}

.input-brackets-border {
  border-top: 1px solid #9e9e9e;
  border-right: 1px solid #9e9e9e;
  border-left: 1px solid #9e9e9e;
  height: 5px;
  width: 100%;
  margin-left: 25px;
}
