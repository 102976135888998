.font {
  font-family: "Roboto";
  font-style: normal;
}

.font .login-input {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #323232;
}

.font .login-sml-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #323232;
}

.login-sml-padding {
  padding-top: 10px;
}

.font .login-from-style {
  align-content: center;
  margin-top: 50px;
  margin-left: 10%;
  width: 80%;
}

.login-input-method {
  display: flex;
  padding-left: 7%;
  padding-top: 30px;
}

.div-login-input {
  text-align: center;
  width: 31%;
  height: 36px;
}

.info-icon {
  margin-left: 23vw;
  cursor: help;
}

.btn-flat {
  text-align: center;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  color: #919191 !important;
}

.btn-flat:focus {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  background-color: #fff;
  color: #8f312d !important;
  border-bottom: 3px solid #8f312d;
  border-radius: 0px;
  text-align: center;
}

.form-control {
  margin-top: 15px;
  border-radius: 5px !important;
}

.form-control::placeholder {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #bababa;
}

.div-auth-method {
  margin-top: 43px;
  display: flex;
}

.div-choose-id {
  margin-top: 30px;
  display: flex;
}

@media (max-width: 575px) {
  .div-select-method {
    width: 70% !important;
  }
}

.div-select-method {
  padding-left: 25px;
  width: 83%;
}

.div-input-name {
  margin-top: 10px;
  margin-bottom: 5px;
  text-align: center;
  display: flex;
  width: auto;
}

.div-input-eng {
  flex-grow: 0.95;
}

.input-eng-border {
  border-top: 1px solid #9e9e9e;
  border-right: 1px solid #9e9e9e;
  border-left: 1px solid #9e9e9e;
  height: 5px;
}

.div-input-int {
  flex-grow: 3.25;
}

.div-int-font {
  margin-left: 25px;
}

.input-int-border {
  border-top: 1px solid #9e9e9e;
  border-right: 1px solid #9e9e9e;
  border-left: 1px solid #9e9e9e;
  height: 5px;
  width: 88%;
  margin-left: 2.5vw;
}

.div-input-brackets {
  flex-grow: 0.25;
  margin-left: 1vw;
}

.input-brackets-border {
  border-top: 1px solid #9e9e9e;
  border-right: 1px solid #9e9e9e;
  border-left: 1px solid #9e9e9e;
  height: 5px;
  width: 100%;
  margin-left: 0px !important;
}

.input-brackets-font {
  /* text-align: start !important; */
}

.div-input-box {
  margin-top: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-box {
  width: 36px;
  height: 36px;
  margin-left: 3.5px;
  margin-right: 3.5px;
  text-align: center;
}

.div-btn {
  text-align: center;
}

.div-eng-input {
  display: flex;
  margin-right: 40px;
}

.div-dis-square {
  height: 36px;
  width: 36px;
  background-color: #c2c2c2;
  margin-left: 3.5px;
  margin-right: 3.5px;
}

.div-margin {
  margin-right: 0.5vw;
}

@media (max-width: 575px) {
  .div-dis-square {
    width: 30px;
    height: 30px;
    margin-right: 2vw !important;
  }
  .input-box {
    width: 30px;
    height: 30px;
    margin-right: 2vw !important;
  }
  .div-margin {
    margin-right: 0px !important;
  }
  .font .login-from-style {
    margin-left: 1%;
    margin-right: 1%;
    width: auto;
  }
  .input-int-border {
    margin-left: 10px !important;
  }
  .div-int-font {
    margin-left: 0px !important;
  }
}
