.loginLUA .bg {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.loginLUA-container {
  background: #ffffff;
  border-radius: 12px;
  border: 1.5px solid #ececec;
}

.loginLUA-title-bg {
  background: #8f312d;
  height: 130px;
  border: 1.5px solid #d6d6d6;
  border-radius: 12px 12px 0px 0px;
  display: flex;
  text-align: center;
}

.div-container {
  margin-bottom: 40px;
}

@media (max-width: 575px) {
  .icon {
    margin: 40px;
    margin-left: 40px !important;
  }
  .lua-font {
    padding-left: 0px !important;
  }
}

.icon {
  margin: 40px;
  margin-left: 70px;
}

.lua-font {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
  letter-spacing: 0.02em;
  color: #ffffff;
  padding-top: 50px;
  padding-left: 90px;
}
