.btn-area {
  display: flex;
  margin-top: 10vh;
  flex-direction: row;
}

.card-title {
  height: 20px;
}

.btn-edit {
  width: 40px;
  background-color: darkturquoise;
}

.btn-del {
  width: 40px;
  background-color: firebrick;
}
