.pdf-title {
  height: 75px;
  text-align: center;
}

.pdf-title .font {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #7d6746;
}

.type-btn {
  background: #ffffff !important;
  box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.2) !important;
  border-radius: 10px !important;
  border-color: transparent !important;
  margin-top: 3vh;
  height: 60px;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
}

.type-btn .btn-container {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: row;
}

.type-btn .font {
  color: #000 !important;
  word-break: break-all;
  padding: 1vh 1vw;
  width: 100%;
}

@media (max-width: 1350px) {
  .type-btn .font {
    word-break: break-all;
    padding: 0vh 0vw;
  }
}

.content-border:hover {
  border: 1px dashed #323232;
}

.card-container {
  width: 25vw !important;
  margin-top: 2vh;
}

@media (max-width: 1100px) {
  .card-container {
    width: 50vw !important;
    margin-top: 2vh;
  }
}
