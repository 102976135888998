.footer-bg-color {
  background: #7d6746;
  color: #fff;
  
}

.footer-font {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}



.footer-bg-color .LUAicon{
  width: 40px;
  height: 40px;
  margin-right: 16px;
}


.footer-bg-color .header{

  display: flex;
  flex-direction: row;
  height: 90px;
  align-items: center;
  
  
  
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 22px;


color: #FFFFFF;
}

.footer-bg-color .headerlast{

  display: flex;
  flex-direction: row;
  height: 90px;
  align-items: center;
  
  
  
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 22px;


color: #FFFFFF;
}


@media (max-width: 767px){
  

  .footer-bg-color .headerlast{
    height: 30px;
  }

}


.footer-bg-color .content{

  
  font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 12px;




color: #FFFFFF;
}

.footer-bg-color .last{
  display: flex;
  flex-direction: row;
}
.footer-bg-color .vertical {
  border-right: 1px solid #FFFFFF;
  height: 80px;
}

@media (max-width: 991px){
  

  .footer-bg-color .vertical {
    border-right: 0px solid #FFFFFF;
  } 
}


.footer-bg-color .iconplustext{
  display: flex;
  flex-direction: row;
  align-items: center;
  
  
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  
  color: #FFFFFF;
}

/* @media (max-width: 575px){
  

  .footer-bg-color .iconplustext{
    font-size: 1vw;
  }
} */

.footer-bg-color .Copyright{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  color: #FFFFFF;
}