.nav-font-not-clicked {
  font-family: "Inter", sans-serif;
  font-style: normal;
  margin-top: 20px;
  margin-right: 20px;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #7d6746;
  text-decoration: none;
}

.nav-font-not-clicked:hover {
  font-family: "Inter", sans-serif;
  font-style: normal;
  margin-top: 20px;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #7d6746;
  text-decoration: none;
}

.nav-font-on-clicked {
  font-family: "Inter", sans-serif;
  font-style: normal;
  margin-top: 20px;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #b1a390;
}

.menu-bg-color {
  color: #fff;
}

.navbar-fixed {
  min-height: 85px;
  height: 85px;
  background-color: #fff;
  align-items: center;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  list-style-type: none;
}

.ul-nav {
  padding: 0;
  margin: 0;
}

.nav-toggle {
  visibility: hidden;
  opacity: 0;
}

.hidden-btn {
  visibility: hidden;
}

.nav-icon {
  margin-left: 2rem;
  /* margin: 2rem; */
  height: 75px;
}

.margin {
  /* padding-right: 40px; */
  padding-left: 40px;
}

.div-btn {
  padding-top: 41px;
  width: 100%;
  text-align: center;
  padding-bottom: 41px;
}

.div-btn-font {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.btn {
  box-shadow: none !important;
}

.btn:focus {
  outline: none !important;
  outline-offset: none !important;
}

.flex {
  display: flex;
  gap: var(--gap, 2srem);
}

.ul-flex {
  display: flex;
  /* margin-top: 35px; */
  align-items: center;
  gap: var(--gap, 9rem);
  align-items: center;
  width: 100%;
}

nav > ul {
  list-style-type: none;
}

@media (max-width: 100em) {
  .ul-flex {
    display: flex;
    /* margin-top: 35px; */
    align-items: center;
    gap: var(--gap, 6rem) !important;
  }
}

@media (max-width: 95em) {
  .ul-flex {
    display: flex;
    align-items: center;
    gap: var(--gap, 5rem) !important;
  }
  .nav-font-not-clicked {
    font-size: 15px;
  }
}
@media (max-width: 90em) {
  .ul-flex {
    display: flex;
    align-items: center;
    gap: var(--gap, 4rem) !important;
  }
  .nav-font-not-clicked {
    font-size: 15px;
  }
}

@media (max-width: 80em) {
  .ul-flex {
    display: flex;
    align-items: center;
    gap: var(--gap, 4rem) !important;
  }
  .nav-font-not-clicked {
    font-size: 15px;
  }
}

@media (max-width: 75em) {
  .ul-flex {
    display: flex;
    align-items: center;
    gap: var(--gap, 4rem) !important;
  }
  .nav-font-not-clicked {
    font-size: 15px;
  }
}

@media only screen and (max-width: 50em) {
  .navbar-fixed {
    justify-content: space-between;
    padding-right: 25px;
  }

  header nav {
    --gap: 2em;
    position: fixed;
    z-index: 99999;
    inset: 0 0 0 30%;
    /* padding: min(30vh, 10rem) 2rem; */
    height: 100%;
    list-style-type: none;
    justify-content: center;
    background-color: #f2efec;
    transform: translateX(100%);
    transition: 1s;
  }

  .ul-flex {
    display: flex;
    flex-direction: column;
    /* align-items: flex-end; */
    gap: var(--gap, 2rem);
  }

  .nav-font-not-clicked {
    float: right;
  }

  .close-nav {
    transform: none;
  }

  .nav-toggle {
    visibility: visible;
    opacity: 1;
  }

  .nav-close-toggle {
    /* align-self: flex-end; */
  }
}

@media (max-width: 703.8px) {
  .navbar-fixed {
    min-height: 12.0772947vw;
    height: 12.0772947vw;
  }

  .nav-icon {
    height: 10.6685633vw;
    /* margin: 2rem; */
  }
}

@media (max-width: 414px) {
  .navbar-fixed {
    min-height: 50px;
    height: 50px;
  }

  .nav-icon {
    margin-left: 7vw;
    height: 44.1678521px;
    /* margin: 2rem; */
  }
}
