.loginDC-container {
  background: #ffffff;
  border-radius: 12px;
}

.div-container {
  padding-top: 75px;
}

.font {
  font-family: "Roboto";
  font-style: normal;
}

.font .login-title {
  padding-top: 45px;
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  color: #7d6746;
}
