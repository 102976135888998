.homepage-font {
  font-family: "Inter";
  font-style: normal;
}

.homepage-font .header {
  color: #7d6746;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
}

.homepage-font .center {
  width: auto;
  text-align: center;
}

.homepage-font .content {
  color: #4e4e4e;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  margin-bottom: 35px;
  margin-left: 15px;
  margin-right: 15px;
}

.img-postion {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hp-margin-top {
  margin-top: 160px;
}

.icon-padding {
  padding-top: 54px;
  margin: 54px;
  text-align: center;
}

.content-margin-top {
  margin-top: 20px;
}



.homepage .icontext{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */
  
  
  color: #4E4E4E;
}


.homepage .targetbanner{
  background-size: cover;
  background-position: center;
  height: 724px;
  background-image: url("../img/png/target-banner.png");
}

.homepage .homepagebanner{
  background-size: cover;
  background-position: center;
  height: 36.9791667vw;
  background-image: url("../img/png/Homepage-banner.png");
}

@media (max-width: 1200px){
  .homepage .reverse{
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 1122.3px){
  .homepage .targetbanner{
    background-size: cover;
    background-position: center;
    height: 64.5vw;
    background-image: url("../img/png/target-smallbanner.png");
  }
  
}

@media (max-width: 855.946111px){
  .homepage .homepagebanner{
    height: 316.521739px;
  }
}

@media (max-width: 598px){
  

  .homepage .pplimg{
    height: 66.88vw
  }

  .homepage .targetbanner{
    height: 385.74px;
  }
  
}

@media (max-width: 620px){
  .homepage .homepagebanner{
    height: 60.8695652vw;
    background-image: url("../img/png/Homepage-smallbanner.png");
  }
}


