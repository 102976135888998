.dcmemberinfo-container {
  height: 100%;
}

.dcmemberinfo-font-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #7d6746;
}

.dcmemberinfo-font-content {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  width: 90%;
}
.dcmemberinfo-font-memNum {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  color: #7d6746;
}

.dcmember-logo {
  background-color: white;
  margin-top: 6vh;
  height: 225px;
  border-radius: 10px;
}

.dcmember-memberinfo {
  background-color: white;
  margin-top: 2vh;
  height: 375px;
  border-radius: 10px;
}

.dcmember-memberDetails {
  background-color: white;
  margin-top: 6vh;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  height: 613px;
}

.dcmember-memberinfo .container {
  padding-top: 2vh;
  padding-left: 2vw !important;
}

.dcmember-memberinfo .title {
  display: flex;
}

.dcmember-memberinfo .content {
  display: flex;
  margin-top: 2.5vh;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
}

.dcmember-memberDetails .first-container {
  padding-top: 2vh;
  padding-left: 2vw !important;
  width: 50%;
  margin-right: 0px !important;
}

.dcmember-memberDetails .sceond-container {
  padding-top: 2vh;
  padding-left: 2vw !important;
  width: 50%;
}

.dcmember-memberDetails .footer {
  display: inline;
  padding-left: 3vw !important;
  width: 95%;
}

.dcmember-memberDetails .title {
  display: flex;
}

.dcmember-memberDetails .content {
  display: flex;
  margin-top: 2.5vh;
  width: 95%;
  justify-content: space-between;
  align-items: flex-end;
  word-break: break-all;
}

.font-padding-left {
  padding-left: 1vw;
}

.dcmember-name-memberNum {
  text-align: center;
  padding-top: 1vh;
}

.font-margin {
  margin-top: 1vh;
}

.hr {
  border-top: 2px solid #7d6746;
  width: 90%;
}

.hr2 {
  border-top: 2px solid #7d6746;
  width: 100%;
  height: 100%;
}

.back-btn {
  text-align: center;
}
