.div-all {
  margin-top: 200px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 250px;
}

.div-check {
  background-color: blue;
}

.font {
  font-family: "Inter";
  font-style: normal;
}

.font .dc-header {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #7d6746;
}

.font .dc-num {
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  color: #7d6746;
}

.font .lua-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #8f312d;
}

.font .icon-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #474d47;
}

.font .icon-content {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  color: #424542;
  padding-top: 6px;
}

.icon-left {
  width: auto;
}

.row-flex {
  padding-top: 40px;
  width: auto;
}

.div-all .iconplustext{
 display: flex;
  flex-direction: row;
  align-items: center;
  
}


.row-flex-cloumn {
  flex-direction: column;
}

.row-flex-row {
  display: flex;
}

.row-width {
  width: auto;
}

.container-row-padding {
  margin-top: 40px;
}


@media (max-width: 575px){

  .font .AboutUsIcon{
    width: 14vw;
  }

  .font .dc-header {
    font-size: 3.8647343vw;
    line-height: 4.58937198vw;
  }
  
  .font .dc-num {
    font-size: 5.555vw;
    line-height: 5.79710145vw;
  }

  .font .LUAICON{
    width: 20vw;
  }

  .font .lua-title {
    font-size: 3.8647343vw;
    line-height: 5.31400966vw;
  }
  
}
