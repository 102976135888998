.DCmain-container {
  margin-top: 15vh;
  margin-bottom: 15vh;
}

.header-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  text-align: center;
  color: #7d6746;
}
.cms-edit-btn {
  position: absolute;
  right: 5vw;
}
