.Verification .bg {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Verification .confirmtext {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  text-align: center;

  color: #7d6746;
}

.Verification .confirmtext-eng {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 36px;
  text-align: center;
  margin-top: 12px;
  color: #7d6746;
}
